import React, { useEffect, useContext } from 'react'
import AboutPage from './venue/about'
import { useRouter } from 'next/router'
import { AuthContext } from 'app/utils/context'

const Index = () => {
  const { push } = useRouter()
  const { state } = useContext(AuthContext)

  useEffect(() => {
    if (state.signedIn) {
      push('/home')
    }
  }, [state.signedIn, push])

  return <AboutPage />
}

export default Index
